// product.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Workcell } from 'src/app/models/workcell.model';
import { ProductView } from 'src/app/models/productView.model';
import { Product } from 'src/app/models/product.model';
import { Deviation } from 'src/app/models/deviation.model';

@Injectable({
  providedIn: 'root', // Service is available app-wide
})
export class ProductService {
  private apiUrl = environment.API_URL;

  constructor(private httpClient: HttpClient) {}

  getProductByDeviationDisplayId(displayId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}Product/ByDeviationDisplayId/${displayId}`).pipe(
      catchError((error) => {
        console.error('Error fetching product by display id:', error);
        return throwError(() => new Error('Failed to fetch product by display id'));
      })
    );
  }

  getProductsByFactoryId(factoryId: number, checkpointId:number): Observable<ProductView[]> {
    return this.httpClient.get<ProductView[]>(`${this.apiUrl}Product/ByFactoryId/${factoryId}/${checkpointId}`).pipe(
      catchError((error) => {
        console.error('Error fetching product by factory:', error);
        return throwError(() => new Error('Failed to fetch products by factory'));
      })
    );
  }

  getProductsByProductionLineId(lineId:number, checkpointId:number): Observable<ProductView[]> {
    return this.httpClient.get<ProductView[]>(`${this.apiUrl}Product/ByProductionLineId/${lineId}/${checkpointId}`);
  }

  getProducts(lineId: number | null, factoryId: number | null, checkpointId: number): Observable<ProductView[]> {
    const requestUrl = lineId !== -1
        ? `${this.apiUrl}Product/ByProductionLineId/${lineId}/${checkpointId}`
        : `${this.apiUrl}Product/ByFactoryId/${factoryId}/${checkpointId}`;

    return this.httpClient.get<ProductView[]>(requestUrl);
  }

  getProductByChassisId(factoryId: number, chassisId:string): Observable<any> {
    return this.httpClient.get<ProductView>(`${this.apiUrl}Product/ByChassisId/${chassisId}/${factoryId}`).pipe(
      catchError((error) => {
        console.error('Error fetching product by chassis Id:', error);
        return throwError(() => new Error('Failed to fetch product by chassis id'));
      })
    );
  }

  getProductById(productId:number, factoryId:number, lineId:number): Observable<any> {
    return this.httpClient.get<Product>(`${this.apiUrl}Product/${productId}/${factoryId}/${lineId}`);
  }

  getProductByFabNumber(fabId:string, factoryId:number, lineId:number): Observable<any> {
    return this.httpClient.get<Product>(`${this.apiUrl}Product/ByFabNumber/${fabId}/${factoryId}/${lineId}`);
  }

  getProductParts(productId: number, factoryId: number, lang: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}Product/${productId}/Parts/${factoryId}/${lang}`).pipe(
      catchError((error) => {
        console.error('Error fetching product parts:', error);
        return throwError(() => new Error('Failed to fetch product parts'));
      })
    );
  }

  getProductDetectionWorkcells(productId: number, factoryId: number, lineId: number): Observable<any> {
    return this.httpClient.get<Workcell[]>(`${this.apiUrl}Product/${productId}/DetectionWorkcells/${factoryId}/${lineId}`);
  }

  getProductCheckImpactingDeviations(productId: number, lineId: number): Observable<any> {
    return this.httpClient.get<Deviation[]>(`${this.apiUrl}Product/CheckImpactingDeviations/${productId}/${lineId}`);
  }

  putProductCheck(productId: number, lineId: number, workcellId: number, userScanned:string): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}Product/Check/${productId}/${lineId}/${workcellId}/${userScanned}`, null).pipe(
      catchError((error) => {
        console.error('Error fetching product check:', error);
        return throwError(() => error);
      })
    );
  }
}
